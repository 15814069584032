export default {
  currentConfigApi: ({ currentConfig }) => {
    let orderDirection = null
    if (typeof currentConfig.sortDesc[0] === 'boolean') {
      orderDirection = currentConfig.sortDesc[0] ? 'desc' : 'asc'
    }
    return {
      perPage: currentConfig.perPage || null,
      page: currentConfig.page && currentConfig.page > 1 ? currentConfig.page : null,
      orderBy: currentConfig.sortBy[0],
      orderDirection: orderDirection
    }
  },
  filtersCount: (state) => {
    return Object.keys(state.filters)
      .filter(key => (state.filters[key]))
      .length
  }
}
