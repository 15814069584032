import {
  LOCALSTORAGE_CACHE_ORDERS_TABLE_CONFIGURATION,
  LOCALSTORAGE_CACHE_ORDERS_FILTERS
} from '@/constants/localStorage'

export default {
  SET_ORDERS (state, orders) {
    state.orders = orders
  },
  SET_ORDER_DETAIL (state, order) {
    state.orderDetail = order
  },
  SET_ORDER_DETAIL_ERROR (state, error) {
    state.orderDetailError = error
  },
  SET_STATUS_CODE_LIST (state, statusCodeList) {
    state.statusCodeList = statusCodeList
  },
  SET_ACTIVE_STEP (state, activeStep) {
    state.activeStep = activeStep
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  TOGGLE_SHOW_FILTERS (state) {
    state.showFilters = !state.showFilters
  },
  ADD_FILTER (state, { field, value }) {
    if (value) {
      const filters = {
        ...state.filters
      }
      filters[field] = value
      state.filters = filters
      sessionStorage.setItem(LOCALSTORAGE_CACHE_ORDERS_FILTERS, JSON.stringify(state.filters))
    }
  },
  SET_FILTERS (state, filters) {
    state.filters = filters
    sessionStorage.setItem(LOCALSTORAGE_CACHE_ORDERS_FILTERS, JSON.stringify(state.filters))
  },
  REMOVE_FILTER (state, field) {
    const filters = {
      ...state.filters
    }
    delete filters[field]
    state.filters = filters
    sessionStorage.setItem(LOCALSTORAGE_CACHE_ORDERS_FILTERS, JSON.stringify(state.filters))
  },
  SET_CURRENT_CONFIG (state, currentConfig) {
    state.currentConfig = currentConfig
    const currentConfigStringify = JSON.stringify(state.currentConfig)
    sessionStorage.setItem(LOCALSTORAGE_CACHE_ORDERS_TABLE_CONFIGURATION, currentConfigStringify)
  },
  SET_CURRENT_CONFIG_FROM_API (state, { currentPAge, total, pages }) {
    state.currentConfig.page = currentPAge
    state.total = total
    state.pageCount = pages
  },
  SET_PAGE (state, page) {
    state.currentConfig.page = page
  },
  SET_PER_PAGE (state, perPage) {
    state.currentConfig.perPage = perPage
  },
  SET_SORT_BY (state, sortBy) {
    state.currentConfig.sortBy = sortBy
  },
  SET_SORT_DESC (state, sortDesc) {
    state.currentConfig.sortDesc = sortDesc
  },
  SET_EXPORT_LOADING (state, exportLoading) {
    state.exportLoading = exportLoading
  }
}
