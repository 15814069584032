import { Orders } from '@/services/FreightApi/Orders'
import { StatusCode } from '@/services/FreightApi/StatusCode'
import { trimObject } from '@/utils/functions'

export default {
  async getApiOrders ({ state, getters, commit }) {
    commit('SET_LOADING', true)

    const filters = trimObject(JSON.parse(JSON.stringify(state.filters)))

    if (filters.statusCode && filters.statusCode.length > 0) {
      filters.statusCode = filters.statusCode.join(',')
    } else {
      delete filters.statusCode
    }

    let orders = new Orders()
    orders = await orders.list({
      ...getters.currentConfigApi,
      ...filters
    })
    if (orders) {
      commit('SET_ORDERS', orders?.data || [])
      commit('SET_CURRENT_CONFIG_FROM_API', orders)
    }
    commit('SET_LOADING', false)
  },
  async getOrderDetail ({ commit }, { orderId }) {
    commit('SET_LOADING', true)

    let order = new Orders()
    const statusCode = new StatusCode()

    order = await order.findById(orderId)

    const statusCodeList = await statusCode.list()

    commit('SET_STATUS_CODE_LIST', statusCodeList || [])

    if (!order.error) {
      order.steppers = order.steppers || []
      const lastOrderStep = order.steppers[order.steppers.length - 1]
      const activeStep = statusCodeList.find(status => { return lastOrderStep === status.name })

      commit('SET_ACTIVE_STEP', statusCodeList.indexOf(activeStep))
      commit('SET_ORDER_DETAIL', order || {})
    } else {
      commit('SET_ORDER_DETAIL_ERROR', order.error)
    }

    commit('SET_LOADING', false)
  },
  clearFilters ({ getters, commit, dispatch }) {
    if (getters.filtersCount > 0) {
      commit('SET_FILTERS', {})
      dispatch('getApiOrders')
    }
  },
  async exportData ({ commit }, period) {
    commit('SET_EXPORT_LOADING', true)
    let ordersExport = new Orders()
    ordersExport = await ordersExport.export({
      ...period
    })
    commit('SET_EXPORT_LOADING', false)
    return ordersExport
  }
}
