import { Notification } from '@/components/Notifications/Notification'

/* eslint-disable no-dupe-class-members */
export class OrderExportCSVNotification extends Notification {
  constructor (args) {
    super({ ...args })
    this.subtitle = 'Seu relatório está disponível para download'
    this.#setDownloadLink()
    this.#setComponent()
    this.#setToastComponent()
  }

  #setDownloadLink () {
    this.downloadLink = this.getPayload()
  }

  #setComponent () {
    this.component = () => import(
      '@/components/Notifications/Types/OrderExportCSVNotification.vue'
    )
  }

  #setToastComponent () {
    this.toast.component = () => import(
      '@/components/Notifications/Types/OrderExportCSVNotificationToast.vue'
    )
  }

  download = () => {
    this.changeRead(true)
    const payload = this.getPayload()
    if (payload && payload.urlFile) {
      window.open(payload.urlFile, '_blank').focus()
    } else {
      alert('URL do arquivo não encontrada!')
    }
  }
}
