import store from '@/store'
/**
 * Helper AppShell auth object
 */
function AppShellAuth () {
  this.listen = (tokenObservable, eventBus, ctx) => {
    if (!tokenObservable) return
    tokenObservable.subscribe({
      next: auth => {
        if (this.token !== auth.authToken || this.account.id !== auth.store.id) {
          this.token = auth.authToken
          this.seller.id = auth.sellerId
          this.account.id = auth.store.id
          this.account.name = auth.store.name
          this.userRoles = auth.userRoles
          store.dispatch('Auth/setAuth', auth)
          eventBus.apply(ctx, [this.event + 'update', this])
        }
      }
    })
  }
}

function AppShellAuthObject (data) {
  this.id = data.id
}
AppShellAuth.prototype.event = 'appshell:auth:'
AppShellAuth.prototype.token = null
AppShellAuth.prototype.seller = new AppShellAuthObject({})
AppShellAuth.prototype.account = new AppShellAuthObject({})
AppShellAuth.prototype.userRoles = []

// single instance
export default new AppShellAuth()
