import { FreightOrdersApi } from '@/services/FreightApi/FreightOrdersApi'

import { ORDER_STATUS_ICONS } from '@/constants/orderStatusIcons'

export class StatusCode extends FreightOrdersApi {
  ICON_BACKGROUND = '#c6d3de'
  ICON_BACKGROUND_COMPLETED = '#7676ff'

  constructor () {
    super()
    this.uri = '/status-code'
  }

  list () {
    return this.instance.get(this.uri + '/get-list')
      .then(response => {
        return response?.data?.data.map((step, index) => {
          return {
            index: index,
            name: step.name,
            label: () => import('@/views/Order/StepLabel.vue'),
            labelAttr: {
              label: 'app.view.order.status.' + step.name
            },
            icon: {
              name: ORDER_STATUS_ICONS[step.name] || '',
              color: '#ffffff',
              background: this.ICON_BACKGROUND
            },
            completeIcon: {
              name: ORDER_STATUS_ICONS[step.name] || '',
              background: this.ICON_BACKGROUND_COMPLETED
            }
          }
        })
      })
      .catch(() => {
        return false
      })
  }
}
