import state from './state'
import actions from '@/store/Notifications/actions'
import mutations from '@/store/Notifications/mutations'
import getters from '@/store/Notifications/getters'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
