const dashboard = 'dashboard'

export const PATH_DASHBOARD = dashboard

export const NAME_DASHBOARD = dashboard

export default {
  path: PATH_DASHBOARD,
  name: NAME_DASHBOARD,
  component: () => import('@/views/Dashboard'),
  meta: {
    breadcrumb: [{ text: 'Dashboard' }],
    title: 'Dashboard',
    returnButton: false
  }
}
