const home = 'home'

export const PATH_HOME = home

export const NAME_HOME = home

export default {
  path: PATH_HOME,
  name: NAME_HOME,
  component: () => import('@/views/Home.vue'),
  meta: {
    title: 'Home',
    auth: true
  }
}
