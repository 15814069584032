const order = 'order'

export const PATH_ORDER = order

export const NAME_ORDER = order

export default {
  path: PATH_ORDER + '/:id',
  name: NAME_ORDER + '-details',
  component: () => import('@/views/Order/OrderDetails'),
  meta: {
    title: 'Detalhes do Pedido',
    breadcrumb: [{ text: 'Pedidos' }, { text: 'Detalhes do Pedido' }],
    returnButton: true,
    back: {
      route: { name: 'orders' }
    }
  }
}
