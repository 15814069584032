import Vue from 'vue'

const moment = require('moment')
require('moment/locale/pt-br')

Vue.use(require('vue-moment'), {
  moment
})

export const $moment = moment
export default {}
