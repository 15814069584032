function trimObject (obj) {
  const newFilters = {}

  // we'll clear the spaces in start and end of key values
  // if value is empty or null, will not enter to object
  Object.keys(obj).forEach(key => {
    let value = obj[key]
    value = typeof value === 'string' ? value.trim() : value

    if (value) {
      newFilters[key] = value
    }
  })

  return newFilters
}

export {
  trimObject
}
