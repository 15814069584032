import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@infralabs/infra-components-vue/dist/infra-components-vue.css'
import IfcComponents from '@infralabs/infra-components-vue'

const options = {}
const vuetifyConfig = {
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#2F3C4E',
        secondary: '#B8B8B9',
        error: '#E44123',
        success: '#45E398'
      },
      dark: {
        primary: '#4caf50'
      }
    }
  }
}

Vue.use(Vuetify)
Vue.use(IfcComponents, options, vuetifyConfig)

export default options
