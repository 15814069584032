<template>
  <div>
    <v-menu
      offset-y
      v-model="active"
      class="notifications"
      origin="center center"
      :nudge-width="drawerWidth"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge :class="{'notifications__not-has-notification': unreadCount < 1}" color="red" :value="unreadCount" offset-x="20" offset-y="31" :content="unreadCount">
          <ifc-button v-bind="attrs" v-on="on" text @click="SET_OPENED(true)">
            <span>Notificações</span>
          </ifc-button>
        </v-badge>
      </template>
      <v-app-bar class="notifications_app-bar" flat fixed absolute color="white">
        <v-spacer></v-spacer>
        <span class="notifications__show-read--text">Mostrar apenas não lidas</span>
        <div class="notifications__show-read--input">
          <v-switch
            v-model="readed"
            :loading="loading"
            inset
            @change="getNotifications()"
          ></v-switch>
        </div>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="primary"
        ></v-progress-linear>
      </v-app-bar>
      <v-container class="notifications__content">
        <v-list two-line>
          <v-app-bar flat dense color="white" class="notifications__app-bar-info">
            <div class="notifications__subtitle">
              Mais Recentes
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="unreadCount > 0"
              class="notifications__mark-all-notifications"
              :loading="loading"
              small
              text
              @click="readAllNotifications()"
            >
              Marcar todas como lidas
            </v-btn>
          </v-app-bar>
          <template>
            <div id="notifications__list" style="max-height: 500px !important; overflow-x: none;overflow-y: scroll;">
              <div style="min-height: 300px !important;">
                <div v-for="(notification, notificationIndex) in formattedNotifications" :key="notificationIndex">
                  <v-divider
                    v-if="notificationIndex"
                    class="notifications__divider"
                  ></v-divider>
                  <div v-if="notification.component" :key="notificationIndex">
                    <component
                      :is="createNotification(notification)"
                      v-bind="{notification}"
                      v-on:changeRead="notification.changeRead"
                    ></component>
                  </div>
                  <Notification
                    v-else
                    v-bind="{...notification}"
                    v-on:changeRead="notification.changeRead"
                  ></Notification>
                </div>
                <v-divider v-if="formattedNotifications.length > 0" class="notifications__divider"></v-divider>
                <v-skeleton-loader
                  v-if="loading"
                  class="alert-skeleton"
                  type="list-item-avatar-three-line"
                ></v-skeleton-loader>
                <div v-if="!loading && formattedNotifications.length === 0" class="notifications__readed-all-messages">
                  <div style="text-align: center;">
                    <v-img
                      src="@/assets/svg/allNotificationsReaded.svg"
                      class="mx-auto"
                      max-width="200"
                    />
                  </div><br>
                  Você leu todas as notificações<br>dos últimos 30 dias.
                </div>
              </div>
              <div v-if="!nextPage && !loading && formattedNotifications.length > 0 && !readed" class="notifications__end-message">
                Estas são todas as notificações<br>dos últimos 30 dias.
              </div>
              <div v-if="!nextPage && !loading && formattedNotifications.length > 0 && readed" class="notifications__end-message">
                Estas são todas as notificações não lidas<br>dos últimos 30 dias.
              </div>
            </div>
          </template>
        </v-list>
      </v-container>
    </v-menu>
    <div v-for="(notification, notificationKey) in activeNotifications" :key="notificationKey">
      <NotificationToast
        :notification="notification"
      ></NotificationToast>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import NotificationToast from '@/components/Notifications/NotificationToast.vue'
import Notification from '@/components/Notifications/Notification.vue'
import { Debounce } from '@/utils/Debounce'
export default {
  name: 'NotificationsDrawer',
  data: () => ({
    debounce: null
  }),
  computed: {
    ...mapState('Notifications', ['loading', 'opened', 'nextPage', 'unreadCount']),
    ...mapGetters('Notifications', ['formattedNotifications', 'activeNotifications']),
    active: {
      get () {
        return this.$store.state.Notifications.opened
      },
      set (newValue) {
        this.SET_OPENED(newValue)
      }
    },
    readed: {
      get () {
        return this.$store.state.Notifications.readed
      },
      set (newValue) {
        this.SET_READED(newValue)
      }
    },
    drawerWidth () {
      return this.$vuetify.breakpoint.width < 380 ? '100%' : 340
    },
    drawerHeight () {
      return window.innerHeight - 58
    }
  },
  methods: {
    ...mapMutations('Notifications', ['SET_OPENED', 'SET_READED']),
    ...mapActions('Notifications', ['getNotifications', 'paginateNotifications', 'readAllNotifications']),
    createNotification: function (notification) {
      return notification.component
    },
    infiniteScrolling: function () {
      setTimeout(() => {
        const listElm = document.querySelector('#notifications__list')
        listElm.addEventListener('scroll', e => {
          if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
            Debounce.execute(this.debounce, () => {
              this.paginateNotifications()
            }, 200)
          }
        })
      }, 1000)
    }
  },
  watch: {
    active (active) {
      if (active) {
        this.infiniteScrolling()
      }
    }
  },
  mounted () {
    this.getNotifications()
  },
  components: {
    Notification,
    NotificationToast
  }
}
</script>
<style scoped>
  .notifications {
     background: blue !important;
  }
  .v-menu__content {
    border-radius: 8px !important;
    padding-bottom: 0;
  }
  .notifications_app-bar-title {
    font-size: 20px;
  }
  .notifications__subtitle {
    font-size: 16px;
    color: #414F62;
    font-weight: bold;
  }
  .notifications__app-bar-info {
    padding: 0;
  }
  .notifications__content {
    margin-top: 58px;
    padding: 0;
  }
  .notifications__show-read--text {
    font-size: 14px;
    color: #879BB4;
  }
  .notifications__show-read--input {
    margin-top: 22px;
    margin-left: 20px;
  }
  .notifications__end-message {
    text-align: center;
    padding: 15px 15px;
    font-size: 14px;
  }
  .notifications__readed-all-messages {
    text-align: center;
    padding: 15px 15px;
    font-weight: bold;
    font-size: 18px;
  }
  .notifications__mark-all-notifications {
    text-transform: inherit;
    font-size: 14px;
    color: #414F62;
    font-weight: bold;
  }
  .notifications__divider {
    margin-left: 15px;
    margin-right: 15px;
    border-color: rgb(0 0 0 / 7%);
  }
  .notifications__divider--first {
    border-color: #F1B51C !important;
    border-width: 1px;
    max-width: 50px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .v-btn.v-btn--text.ifc-button--primary {
    background-color: unset !important;
  }
  .notifications__not-has-notification {
    padding-right: 0 !important;
  }
</style>
