export default {
  notificationsCount: ({ notifications }) => {
    return notifications.length
  },
  formattedNotifications: ({ notifications }) => {
    return notifications
  },
  notificationsParams: ({ readed, nextPage }) => {
    return {
      page: nextPage && nextPage > 1 ? nextPage : null,
      read: readed ? false : null
    }
  },
  activeNotifications: ({ notifications }) => {
    return notifications.filter(notification => (notification.active))
  }
}
