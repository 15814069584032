export default {
  SET_TOKEN (state, token) {
    state.token = token
  },
  SET_SELLER (state, seller) {
    state.seller = seller
  },
  SET_ACCOUNT (state, { id, name, slug }) {
    state.account = {
      id, name, slug
    }
  },
  SET_USER_RULES (state, userRules) {
    state.userRules = userRules
  }
}
