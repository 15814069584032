<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'LoggedView',
  mounted () {
    this.$socket.initialize()
  },
  destroyed () {
    this.$socket.disconnect()
  }
}
</script>
