import { FreightApi } from './FreightApi'

import { FREIGHT_API_NOTIFICATION_URL } from '@/constants/enviroments'

export class Notification extends FreightApi {
  constructor () {
    super(FREIGHT_API_NOTIFICATION_URL)
    this.uri = '/notifications'
  }
}
