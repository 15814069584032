<template>
  <v-snackbar
    top
    absolute
    centered
    :timeout="notification.timeout || timeout"
    :color="type"
    elevation="24"
    v-model="active"
    class="notification-toast"
  >
    <v-alert :type="type" close-icon="close">
      <div>
        <span>
          Você recebeu uma notificação
        </span>
      </div>
    </v-alert>
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="active = false">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'NotificationToast',
  data: () => ({
    active: true,
    type: 'success',
    timeout: 20000
  }),
  methods: {
    createNotification: function (notification) {
      return notification.toast.component
    }
  },
  props: {
    notification: {
      required: true,
      type: Object
    }
  }
}
</script>
<style scoped>
  .notification-toast {
    z-index: 999 !important;
  }
  .notification-toast .v-alert__wrapper {
    color: #008847 !important;
  }
  .notification-toast >>> .v-snack__content {
    padding: 0px !important;
  }
  .notification-toast >>> .v-alert {
    margin-bottom: 0px !important;
  }
</style>
