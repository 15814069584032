import {
  FREIGHT_API_API_TOKEN,
  FREIGHT_API_KEY,
  FREIGHT_API_ORDERS_BASE_URL,
  FREIGHT_API_ORDERS_V2_BASE_URL,
  FREIGHT_API_TENANT_ID,
  NODE_ENV,
  ORDER_V2_STORE_IDS
} from '@/constants/enviroments'
import { Axios } from '@/plugins/axios/axios'
import auth from '@/utils/app-shell-auth'
import moment from 'moment'
import errorsDictionary from './errorsDictionary'

export class FreightOrdersApi extends Axios {
  #uri

  constructor () {
    const uriBase = ''
    super({ baseURL: FREIGHT_API_ORDERS_BASE_URL + uriBase })
    this.#requestInterceptor()
    this.#responseInterceptor()
  }

  get uri () {
    return this.#uri
  }

  set uri (uri) {
    this.#uri = uri
  }

  #requestInterceptor () {
    this.instance.interceptors.request.use(
      async (config) => {
        if (NODE_ENV === 'development') {
          config.headers.Authorization = `Bearer ${FREIGHT_API_API_TOKEN}`
          config.headers['x-tenant-id'] = FREIGHT_API_TENANT_ID
          config.headers['api-key'] = FREIGHT_API_KEY
        } else {
          config.headers['X-Tenant-Id'] =
            window.parent.IFC_VARIABLES.headers['x-tenant-id']
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  #responseInterceptor () {
    this.instance.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        const statusCode = error?.response?.status
        this.#responseStatusCodeActions(statusCode)
        return Promise.reject(error)
      }
    )
  }

  #responseStatusCodeActions (statusCode) {
    switch (statusCode) {
      case 401:
        break
      case 403:
        break
      case 404:
        break
    }
  }

  list (params = {}) {
    const {
      orderCreatedAtFrom,
      orderCreatedAtTo,
      orderUpdatedAtFrom,
      orderUpdatedAtTo
    } = params

    const diffCreated = moment(orderCreatedAtTo).diff(orderCreatedAtFrom, 'day')

    if (diffCreated < 0) {
      params.orderCreatedAtTo = orderCreatedAtFrom
      params.orderCreatedAtFrom = orderCreatedAtTo
    }

    if (orderUpdatedAtFrom && orderUpdatedAtTo) {
      const diffUpdated = moment(orderUpdatedAtTo).diff(
        orderUpdatedAtFrom,
        'day'
      )
      if (diffUpdated < 0) {
        params.orderUpdatedAtTo = orderUpdatedAtFrom
        params.orderUpdatedAtFrom = orderUpdatedAtTo
      }
    }

    let url = ''
    if (this.checkStoresShipmentOrderEnabled()) {
      url = FREIGHT_API_ORDERS_V2_BASE_URL
    } else {
      // eslint-disable-next-line no-unused-vars
      url = this.#uri
    }

    return this.instance
      .get(this.#uri, { params })
      .then((response) => response?.data)
      .catch(() => {
        return false
      })
  }

  findById (id, params = {}) {
    let url = ''
    if (this.checkStoresShipmentOrderEnabled()) {
      url = FREIGHT_API_ORDERS_V2_BASE_URL
    }
    if (url === undefined || url === '') {
      url = this.#uri
    }

    return this.instance
      .get(`${url}/${id}`, { params })
      .then((response) => {
        const orderData = response?.data?.data
        orderData.history = (orderData?.historyOrderByASC || []).reverse()

        return orderData
      })
      .catch((error) => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  create (data) {
    return this.instance
      .post(this.#uri, data)
      .then((response) => ({
        status: true,
        data: response?.data?.data
      }))
      .catch((error) => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  update (id = null, data = {}, params = {}) {
    const uri = id ? `${this.#uri}/${id}` : this.#uri
    return this.instance
      .put(uri, data, { params })
      .then((response) => ({
        status: true,
        data: response?.data?.data
      }))
      .catch((error) => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  delete (id = null, params = {}) {
    const uri = id ? `${this.#uri}/${id}` : this.#uri
    return this.instance
      .delete(uri, { params })
      .then((response) => ({
        status: true,
        data: response?.data?.data
      }))
      .catch((error) => ({
        status: false,
        error: this.checkResponseError(error)
      }))
  }

  checkResponseError (error) {
    if (error?.response?.status) {
      const response = error.response
      switch (response.status) {
        case 400:
          return this.errorsParse(response?.data?.errors || {})
        case 404:
          return response?.data
        case 500:
          return response?.data
        default:
          return response?.data
      }
    }
    return false
  }

  errorsParse (errors) {
    const errorsFormated = {}
    if (errors instanceof Object) {
      for (const key in errors) {
        errorsFormated[key] = this.errorParse(errors[key])
      }
    }
    return errorsFormated
  }

  errorParse (fieldErrors) {
    if (fieldErrors && Array.isArray(fieldErrors)) {
      return fieldErrors?.map((error) => errorsDictionary[error])
    }
    return []
  }

  checkStoresShipmentOrderEnabled () {
    const enabledStoresShipmentOrder = (ORDER_V2_STORE_IDS || '').split(',')
    const accountId = auth.account.id

    return !!enabledStoresShipmentOrder.includes(accountId)
  }
}
