<template>
  <v-container fluid>
    <div v-if="showView">
      <LoggedSkeleton :show-view="showView">
        <LoggedView/>
      </LoggedSkeleton>
    </div>
    <div v-else>
      <LoggedSkeleton>
        <LoggedLoading/>
      </LoggedSkeleton>
    </div>
  </v-container>
</template>

<script>
import LoggedSkeleton from './LoggedSkeleton'
import LoggedLoading from './LoggedLoading'
import LoggedView from './LoggedView'
import { mapState } from 'vuex'
export default {
  name: 'LayoutLogged',
  data: () => ({
    showView: true
  }),
  computed: {
    ...mapState('Auth', ['account'])
  },
  methods: {
    accountChange () {
      this.showView = false
      this.checkIfRouteIsOrderDetails()
      setTimeout(() => {
        this.showView = true
      }, 100)
    },
    checkIfRouteIsOrderDetails () {
      if (this.$route.name === 'order-details') {
        this.redirectToOrdersView()
      }
    },
    redirectToOrdersView () {
      this.$router.push({
        name: 'orders'
      })
    }
  },
  watch: {
    account: 'accountChange'
  },
  components: {
    LoggedSkeleton,
    LoggedLoading,
    LoggedView
  }
}
</script>
