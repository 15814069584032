import { $moment } from '@/plugins/moment/moment'
import store from '@/store'
import { JSONParse } from '@/utils/JSONParse'

export class Notification {
  id;
  type;
  read;
  time = '';
  title = '';
  subtitle = '';
  description = '';
  loadingRead = false;
  component = null;
  toast = {};
  active = true;
  createdAt;
  link = null;
  #payload;

  constructor ({ _id, notificationType, read, payload, active, createdAt }) {
    this.id = _id
    this.type = notificationType
    this.read = read
    this.active = active || false
    this.createdAt = createdAt
    this.#payload = payload

    this.#setTime()
  }

  getPayload = () => {
    if (typeof this.#payload === 'string') return JSONParse(this.#payload)
    return this.#payload
  }

  changeRead = async (read) => {
    this.loadingRead = true

    const status = await store.dispatch('Notifications/putApiNotifications', {
      notificationId: this.id,
      read: read
    })
    if (status) {
      this.read = read
      await store.dispatch('Notifications/getNotifications')
    }

    this.loadingRead = false
  }

  #setTime () {
    const time = $moment(this.createdAt).fromNow()
    this.time = time
  }
}
