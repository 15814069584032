<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="10" offset-xl="1" class="logged-loading__col">
        <v-skeleton-loader
          elevation="1"
          type="actions, divider, table-thead"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" xl="10" offset-xl="1" class="logged-loading__col">
        <v-skeleton-loader
          elevation="1"
          type="table-tbody, table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'LoggedLoading'
}
</script>
<style scoped>
  .logged-loading__col {
    padding: 0 !important;
  }
</style>
