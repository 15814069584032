<template>
  <v-list-item
    class="order-export-notification"
    :class="{
      'order-export-notification__read': !read,
      'order-export-notification__unread': read,
    }"
    :link="route ? true : false"
    :to="route"
  >
    <v-list-item-action>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :loading="loadingRead"
            @click="isRead = !read"
            @mouseover="readHover = true"
            @mouseleave="readHover = false"
          >
            <v-icon v-if="read && readHover" color="success lighten-1">
              panorama_fish_eye
            </v-icon>
            <v-icon v-if="!read" x-small color="success"> lens </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span v-if="!read">Marcar como lida</span>
          <span v-else>Marcar como não lida</span>
        </template>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-content>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-list-item-action-text class="notification__datetime" v-on="on" v-text="time" />
        </template>
        <template v-slot:default>
          <span>{{ createdAt | moment("DD/MM/YYYY HH:mm:ss") }}</span>
        </template>
      </v-tooltip>
      <v-list-item-title v-text="title" />
      <span class="notification__subtitle">
        {{ subtitle }}
        <v-btn icon v-if="link" small elevation="0" color="primary" :href="link" target="_blank">
          <v-icon small>open_in_new</v-icon>
        </v-btn>
      </span>
    </v-list-item-content>
    <slot></slot>
  </v-list-item>
</template>
<script>
export default {
  name: 'Notification',
  data: () => ({
    readHover: false
  }),
  computed: {
    isRead: {
      get () {
        return this.read
      },
      set (newValue) {
        this.$emit('changeRead', newValue)
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    read: {
      type: Boolean,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    loadingRead: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    link: {
      type: String
    },
    description: {
      type: String
    },
    time: {
      type: String,
      required: true
    },
    route: {
      type: Object
    }
  }
}
</script>
<style scoped>
.order-export-notification__read {
  font-weight: bold !important;
}
.order-export-notification__unread {
  font-weight: normal !important;
}
.notification__subtitle {
  font-weight: 400;
}
.notification__datetime {
  font-weight: 400;
  color: #a5afb8 !important;
}
</style>
