import getService from '@/utils/get-service'

export const NODE_ENV = process.env.NODE_ENV

export const FREIGHT_API_ADMIN_BASE_URL =
  getService().FREIGHT_API_ADMIN_BASE_URL
export const FREIGHT_API_ORDERS_BASE_URL =
  getService().FREIGHT_API_ORDERS_BASE_URL
export const FREIGHT_API_INVOICES_BASE_URL =
  getService().FREIGHT_API_INVOICES_BASE_URL
export const FREIGHT_API_NOTIFICATION_URL =
  getService().FREIGHT_API_NOTIFICATION_URL
export const FREIGHT_API_KEY = window.parent.IFC_VARIABLES.headers['api-key']
export const FREIGHT_API_API_TOKEN = window.parent.IFC_VARIABLES.auth.token
export const FREIGHT_API_TENANT_ID = window.parent.IFC_VARIABLES.headers['x-tenant-id']

export const DASHBOARD_IFRAME_URL = process.env.VUE_APP_DASHBOARD_IFRAME_URL

export const SOCKET_BASE_URL = getService().SOCKET_BASE_URL
export const SOCKET_PATH = process.env.VUE_APP_SOCKET_PATH
export const FREIGHT_API_ORDERS_V2_BASE_URL = process.env.FREIGHT_API_ORDERS_V2_BASE_URL
export const MFE_URL = process.env.VUE_APP_MFE_URL

export const APPSHELL_URL = process.env.VUE_APP_APPSHELL_URL

export const I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE
export const I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE
export const ORDER_V2_STORE_IDS = process.env.VUE_APP_ORDER_V2_STORE_IDS
