const getUrl = (path) => {
  if (process.env.NODE_ENV === 'development') {
    return path
  }
  return window.parent.IFC_VARIABLES.baseURL + path
}

const getService = () => {
  return {
    FREIGHT_API_ADMIN_BASE_URL: getUrl(process.env.VUE_APP_FREIGHT_API_ADMIN_PATH),
    FREIGHT_API_ORDERS_BASE_URL: getUrl(process.env.VUE_APP_FREIGHT_API_ORDERS_PATH),
    FREIGHT_API_INVOICES_BASE_URL: getUrl(process.env.VUE_APP_FREIGHT_API_INVOICES_PATH),
    FREIGHT_API_NOTIFICATION_URL: getUrl(process.env.VUE_APP_FREIGHT_API_NOTIFICATION_PATH),

    SOCKET_BASE_URL: (process.env.NODE_ENV === 'development')
      ? process.env.VUE_APP_BASE_URL
      : window.parent.IFC_VARIABLES.baseURL
  }
}

export default getService
