import Vue from 'vue'
import Vuex from 'vuex'
import Notifications from '@/store/Notifications'
import Orders from '@/store/Orders'
import Auth from '@/store/Auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Notifications,
    Orders,
    Auth
  }
})
