export const breadcrumbBase = {
  text: 'Página inicial',
  to: '/?redirect=true'
}

const prepareHomepage = (route) => {
  if (route?.meta?.breadcrumb) {
    route.meta.breadcrumb.unshift(breadcrumbBase)
  }

  return route
}

export default prepareHomepage
