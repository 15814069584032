import { Notification } from '@/components/Notifications/Notification'

/* eslint-disable no-dupe-class-members */
export class UploadFreightsErrorNotification extends Notification {
  constructor (args) {
    super({ ...args })
    const payload = this.getPayload()
    this.setError(payload)
    this.#setComponent()
  }

  #setComponent () {
    this.component = () => import(
      '@/components/Notifications/Types/UploadFreightsErrorNotification.vue'
    )
  }

  getLinks () {
    return this.getPayload()?.errors.map(function ({ url }) {
      return url
    })
  }

  setError (payload) {
    const fileNameErrors = payload?.errors.map(({ name }) => name)
    const list = new Intl.ListFormat('pt-br', { style: 'long', type: 'conjunction' }).format(fileNameErrors)
    this.title = 'Importação de estoque ocorreu um erro'
    this.subtitle = `Ocorreu um erro no(s) seguinte(s) arquivo(s) ${list}.`
  }

  download = () => {
    this.changeRead(true)
    this.downloadFiles()
  }

  downloadFiles () {
    const links = this.getLinks()
    if (links?.length) {
      return links.forEach((urlFile) => {
        window.open(urlFile, '_blank')
      })
    }

    const payload = this.getPayload()
    if (payload?.urlFile) return window.open(payload.urlFile, '_blank')

    alert('URL do arquivo não encontrada!')
  }
}
