const DEFAULT_CURRENT_CONFIG = {
  sortBy: [
    'orderUpdatedAt'
  ],
  sortDesc: [
    true
  ],
  page: 1,
  perPage: 10
}

export default {
  orders: [],
  orderDetail: {},
  orderDetailError: {},
  statusCodeList: [],
  activeStep: -1,
  total: 0,
  filters: {
    orderCreatedAt: null,
    orderCreatedAtFrom: null,
    orderCreatedAtTo: null,
    orderUpdatedAt: null,
    orderUpdatedAtFrom: null,
    orderUpdatedAtTo: null,
    search: null
  },
  filtersExpire: null,
  pageCount: 0,
  loading: false,
  showFilters: false,
  currentConfig: DEFAULT_CURRENT_CONFIG,
  exportLoading: false
}
