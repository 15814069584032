export const ORDER_STATUS_ICONS = {
  created: 'star_rate',
  'order-created': 'star_rate',
  'operational-problem': 'warning',
  'carrier-possession': 'outbox',
  'first-delivery-failed': 'warning',
  'address-error': 'warning',
  'shippment-loss': 'warning',
  'hub-transfer': 'arrow_forward',
  'delivery-route': 'double_arrow',
  'shippment-returned': 'undo',
  'zip-code-not-serviced': 'warning',
  'customer-refused': 'warning',
  'address-not-found': 'error',
  'away-customer': 'notification_important',
  'shippment-stolen': 'warning',
  'tax-stop': 'warning',
  dispatched: 'redo',
  'waiting-for-collection': 'redo',
  'shippment-returning': 'warning',
  'delivered-success': 'check_circle',
  'waiting-post-office-pickup': 'warning',
  damage: 'warning',
  'unknown-customer': 'warning',
  invoiced: 'attach_money',
  'in-transit': 'linear_scale',
  'delivery-failed': 'warning',
  canceled: 'close',
  'out-for-delivery': 'maps_home_work',
  'order-dispatched': 'redo'
}
