<template>
  <v-row>
    <v-col cols="12" xl="10" offset-xl="1">
      <ifc-container
        class="logged"
        :breadcrumb="route.breadcrumb"
        :returnButton="route.returnButton"
        @onGoBack="back"
      >
        <template slot="action">
          <Notifications v-if="showView"/>
        </template>
        <template slot="header">
          <v-app-bar color="transparent" flat class="logged__container-header" style="padding: 0 !important;">
            <ifc-typography :text="route.title" typography-style="h2"/>
            <v-spacer></v-spacer>
            <ifc-button v-if="route.action" color="primary" large @click="action">
              <span v-html="route.action.text"></span>
              <v-icon v-html="route.action.icon"></v-icon>
            </ifc-button>
          </v-app-bar>
        </template>
        <slot></slot>
      </ifc-container>
    </v-col>
  </v-row>
</template>

<script>
import Notifications from '@/components/Notifications'
export default {
  name: 'LayoutLoggedSkeleton',
  computed: {
    route () {
      return this.$route.meta
    }
  },
  methods: {
    back () {
      if (this.route?.back?.route) {
        this.$router.push(this.route.back.route)
      } else {
        this.$router.go(-1)
      }
    },
    action () {
      if (this.route?.action?.route) {
        this.$router.push(this.route.action.route)
      }
      if (this.route?.action?.function) {
        this.route.action.function()
      }
      if (this.route?.action?.ifcEditor) {
        this.$IfcEditor(this.route?.action?.ifcEditor)
      }
    }
  },
  props: {
    showView: {
      default: false
    }
  },
  components: {
    Notifications
  }
}
</script>
<style>
  .logged__container-header .v-toolbar__content {
    padding: 0 !important;
  }
</style>
