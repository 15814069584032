<template>
  <v-app class="app__content">
    <router-view />
  </v-app>
</template>

<script>
import router from './router'

export default {
  name: 'App',
  props: ['location'],
  data () {
    return {
      newRoute: ''
    }
  },
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },
  methods: {
    handleRouteChange (event) {
      router.push(event.detail.newRoute)
    }
  },
  created () {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
        `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/order`,
        ''
      )
      : this.$route.fullPath
  },
  mounted () {
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>
<style>
.app__content {
  background: #FAFAFC !important;
}

.ifc-plugins-notify-text, .ifc-plugins-notify-title {
  white-space: pre-line;
}
</style>
