const orders = 'orders'

export const PATH_ORDERS = orders

export const NAME_ORDERS = orders

export default {
  path: PATH_ORDERS,
  name: NAME_ORDERS,
  component: () => import('@/views/Orders'),
  meta: {
    breadcrumb: [{ text: 'Pedidos' }],
    title: 'Pedidos',
    returnButton: false
  }
}
