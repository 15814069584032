import prepareHomepage from '@/router/guard/prepareHomepage'
import LoggedLayout from '@/layout/Logged'
import HOME from '@/router/routes/home'
import ORDER from '@/router/routes/order'
import ORDERS from '@/router/routes/orders'
import DASHBOARD from '@/router/routes/dashboard'
import DASHBOARD_MONITORING from '@/router/routes/dashboardMonitoring'

export default [
  {
    path: '/',
    component: LoggedLayout,
    children: [
      prepareHomepage(HOME),
      prepareHomepage(ORDER),
      prepareHomepage(ORDERS),
      prepareHomepage(DASHBOARD),
      prepareHomepage(DASHBOARD_MONITORING)
    ],
    redirect: {
      path: '/orders'
    }
  }
]
