import NotificationTypes from '@/components/Notifications/Types'

const formatNotification = notification => {
  return new NotificationTypes[notification.type]({
    ...notification
  })
}

export default {
  SET_OPENED (state, opened) {
    state.opened = opened
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_READED (state, readed) {
    state.readed = readed
  },
  SET_NOTIFICATIONS (state, notifications) {
    state.notifications = notifications.map(notification => (formatNotification(notification)))
  },
  PUSH_NOTIFICATIONS (state, notifications) {
    notifications.map(notification => {
      return state.notifications.push(formatNotification(notification))
    })
  },
  ADD_NOTIFICATION (state, notification) {
    state.notifications.unshift(formatNotification(notification))
  },
  SET_NEXT_PAGE (state, { total, currentPage, perPage }) {
    if ((perPage * (currentPage + 1)) > total) {
      state.nextPage = false
    } else {
      state.nextPage = state.nextPage + 1
    }
  },
  RESET_NEXT_PAGE (state, nextPage) {
    state.nextPage = nextPage
  },
  SET_UNREAD_COUNT (state, unreadCount) {
    state.unreadCount = unreadCount
  },
  ADD_UNREAD_COUNT (state) {
    state.unreadCount = state.unreadCount + 1
  },
  SET_TOTAL_ITEMS (state, totalItems) {
    state.totalItems = totalItems
  }
}
