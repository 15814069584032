import { FreightOrdersApi } from '@/services/FreightApi/FreightOrdersApi'
import auth from '@/utils/app-shell-auth'
import {
  NODE_ENV,
  FREIGHT_API_TENANT_ID,
  FREIGHT_API_ORDERS_V2_BASE_URL,
  ORDER_V2_STORE_IDS
} from '@/constants/enviroments'

export class Orders extends FreightOrdersApi {
  constructor () {
    super()
    this.uri = '/orders'
  }

  export (data) {
    let url = ''
    if (this.checkStoresShipmentOrderEnabled()) {
      url = FREIGHT_API_ORDERS_V2_BASE_URL
    } else {
      url = this.uri
    }
    return this.instance
      .post(url + '/export', data)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  checkStoresShipmentOrderEnabled () {
    const enabledStoresShipmentOrder = (ORDER_V2_STORE_IDS || '').split(',')
    let accountId = ''

    if (NODE_ENV === 'development') {
      accountId = FREIGHT_API_TENANT_ID
    } else {
      accountId = auth.account.id
    }

    return !!(enabledStoresShipmentOrder.includes(accountId))
  }
}
