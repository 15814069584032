import store from '@/store'

/* eslint-disable no-dupe-class-members */
export class Notification {
  #socket;

  constructor (socket) {
    this.#socket = socket
    this.#onNotification()
  }

  #onNotification () {
    this.#socket.on('notification', this.makeNotification)
  }

  makeNotification (notification) {
    store.commit('Notifications/ADD_NOTIFICATION', {
      ...notification,
      active: true
    })
    store.commit('Notifications/ADD_UNREAD_COUNT')
  }
}
